import {TimeClockStatus, useTimeclock} from './hooks/useTimeclock';
import {useTranslate} from '../../../../../../app/function/translate';

const ToolbarTimeClock = ({period}: any) => {
  const {createTimelog, createLunchLog, currentStatus, stateLabel, stateLunchLabel} = useTimeclock();
  const translate = useTranslate();

  return (
    <div className='d-flex flex-column flex-sm-row align-items-center gap-2 gap-lg-4'>
      <div className='d-flex align-items-center'>
        <p className='me-3 mb-0' style={{fontSize: '16px', lineHeight: '25.41px', marginBottom: '0'}}>
          {translate('DASHBOARD-USER-LABEL-CE')}
          <b>
            {' '}
            {currentStatus === TimeClockStatus.NO_LOGGED
              ? translate(TimeClockStatus.CLOCKED_OUT)
              : translate(currentStatus)}
          </b>
        </p>
      </div>

      <div className='d-flex align-items-center gap-2'>
        <button
          onClick={createTimelog}
          className='btn btn-sm btn-success'
          style={{fontSize: '16px', lineHeight: '32.41px', marginTop: '8px'}}
        >
          {stateLabel}
        </button>
        <button
          onClick={createLunchLog}
          disabled={currentStatus === TimeClockStatus.CLOCKED_OUT || currentStatus === TimeClockStatus.NO_LOGGED}
          className='btn btn-sm btn-light-success'
          style={{fontSize: '16px', lineHeight: '32.41px', marginTop: '8px'}}
        >
          {stateLunchLabel}
        </button>
      </div>
    </div>
  );
};

export {ToolbarTimeClock};
