import {ToolbarTimeClock} from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarTimeClock';
import {useTranslate} from '../../function/translate';
import {UsersListToolbar} from '../../modules/apps/user-management/users-list/components/header/UserListToolbar';
import {useListView} from '../../modules/apps/user-management/users-list/core/ListViewProvider';

const UsersListHeader = ({period, setPeriod}: any) => {
  const translate = useTranslate();
  return (
    <div className='card-header border-0 pt-6'>
      {/* <UsersListSearchComponent /> */}
      <div className='card-title shown'>
        {/* begin::Search */}
        <div className='d-flex align-items-center position-relative my-1 custom-table-logs-title'>
          {/*start title*/}
          <div className='d-flex align-items-center'>
            <p className='me-2 mb-0' style={{fontSize: '16px', lineHeight: '25.41px', marginBottom: '0'}}>
              {translate('USER_PREVIOUS_PERIOD')}
            </p>
            <div className='form-check form-switch mb-0'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='togglePeriod'
                checked={period}
                onChange={() => setPeriod((prev) => !prev)}
              />
            </div>
            <p className='ms-2' style={{fontSize: '16px', lineHeight: '25.41px', marginBottom: '0'}}>
              {translate('USER_CURRENT_PERIOD')}
            </p>
          </div>
        </div>
        {/* end::Search */}
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <ToolbarTimeClock period={period} setPeriod={setPeriod} />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  );
};

export {UsersListHeader};
