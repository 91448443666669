/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components';
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers';
import {useTranslate} from '../../../../../../function/translate';
import {DeleteAlert, DeleteCompleted} from '../../../../../../hooks/customAlert';
import {useListView} from '../../core/ListViewProvider';
import {useQueryResponse} from '../../core/QueryResponseProvider';
import {deleteClientManager, sendEmailForm} from '../../core/_requests';

type Props = {
  id: ID;
  userId: ID;
};

const ClientManagerActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate, onSelect, selected} = useListView();
  const {query} = useQueryResponse();
  const queryClient = useQueryClient();
  const translate = useTranslate();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };

  const sendEvaluationEmail = async () => {
    try {
      const data = await sendEmailForm(id);
      toast.success(data?.message || '');
    } catch (error: any) {
      toast.error(error?.message || '');
    }
  };

  const deleteItem = useMutation(() => deleteClientManager(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async (data) => {
      // ✅ update detail view directly
      toast.success(data?.data?.message || '');
      selected.includes(id) && onSelect(id);
      DeleteCompleted(translate('ALERT-DELETE'), translate('ALERT-DELETE2'));
      queryClient.invalidateQueries([`${QUERIES.CLIENT_MANAGERS_LIST}-${query}`]);
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.message || error?.message || '');
    },
  });

  const deleteF = async () => {
    const confirmed = await DeleteAlert({
      title: translate('ALERT-TITLE'),
      text: translate('ALERT-TEXT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('ALERT-CONFIRM'),
    });
    if (confirmed) {
      deleteItem.mutate();
    }
  };

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm custom-button-table-action'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3 submenu-custom'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {translate('USERS.ADD-BUTTON-EDIT')}
          </a>
        </div>
        {/* end::Menu item */}
        <div className='menu-item px-3 submenu-custom'>
          <a className='menu-link px-3' onClick={sendEvaluationEmail}>
            {translate('CLIENT_MANAGER.SEND-EMAIL-BUTTON')}
          </a>
        </div>
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link submenu-custom text-danger px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => {
              deleteF();
            }}
          >
            {translate('USERS.ADD-BUTTON-DELETE')}
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  );
};

export {ClientManagerActionsCell};
