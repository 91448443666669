import {FC, useState, createContext, useContext, useMemo} from 'react';
import {initialModal, WithChildren} from '../../../../../../_metronic/helpers';

const ModalUseContext = createContext(initialModal);

const ModalUseProvider: FC<WithChildren> = ({children}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <ModalUseContext.Provider
      value={{
        open,
        setOpen,
      }}
    >
      {children}
    </ModalUseContext.Provider>
  );
};

const useModalHandlers = () => useContext(ModalUseContext);

export {ModalUseProvider, useModalHandlers};
