import axios from 'axios';
import {ID} from '../../../../../../_metronic/helpers';
import {User, UserLogs} from './_models';

const API_URL = process.env.REACT_APP_THEME_API_URL;
const API_URL2 = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/user`;

const getUsers = (query: string) => {
  return '';
};

const getUserById = async (id: ID) => {
  const data: any = await axios.get(`${API_URL2}/users/get/${id}`);
  const dataResponse: any = data.data;

  return dataResponse;
};

const createUser = async (user: any) => {
  const data: any = await axios.post(`${API_URL2}/users/create/new`, user);

  return data;
};

const updateUser = (user: User) => {};

const deleteUser = (userId: ID) => {};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
  return axios.all(requests).then(() => {});
};

export const GET_TODAY_TIMELOG = `${API_URL}/timelog/all`;

export const getUseData = async (query: string) => {
  const data: any = await axios.get(`${API_URL2}/users/user-list/get?${query}`);
  const dataResponse = data.data;
  return dataResponse;
};

export const getTimelogs = async (id: ID) => {
  const data: any = await axios.get(`${API_URL2}/timelog/${id}`);
  const dataResponse: UserLogs = data.data;

  return dataResponse;
};

export const getDepartments = async () => {
  const data: any = await axios.get(`${API_URL2}/department`);
  const dataResponse: any = data?.data;

  return dataResponse;
};

export const getRoles = async () => {
  const data: any = await axios.get(`${API_URL2}/roles/`);
  const dataResponse: any = data?.data;

  return dataResponse;
};

export const update = async (user: any, id: ID) => {
  const data: any = await axios.post(`${API_URL2}/users/update-info/${id}`, user);

  return data;
};

export const deleteUserData = async (id: any) => {
  const data: any = await axios.delete(`${API_URL2}/users/delete/${id}`);

  return data;
};

export const getExportTimeLog = async (filters) => {
  const data: any = await axios.get(`${API_URL2}/timelog/admin/export-users-logs`, {params: filters});
  console.log(data);
  const dataResponse = data.data.data.data;

  return dataResponse;
};

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser};
