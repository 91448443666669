import {useEffect, useMemo, useState} from 'react';
import {useTable} from 'react-table';
import {usersColumns} from '../components/_columns';
import {useQuery} from 'react-query';
import {getTimelogsHistory} from '../services';
import {useAuth} from '../../../modules/auth';
import {useTranslate} from '../../../function/translate';

export const useTimelogs = (currentPeriod?: string | null) => {
  const currentHour = new Date().getHours();
  const translate = useTranslate();

  const getGreating = () => {
    if (currentHour < 12) {
      return translate('DASHBOARD-USER-GM');
    }
    if (currentHour < 18) {
      return translate('DASHBOARD-USER-GA');
    }
    return translate('DASHBOARD-USER-GE');
  };

  const {currentUser} = useAuth();
  const greating = useMemo(getGreating, [currentHour]);

  const columns = useMemo(() => usersColumns, []);

  const {
    data: resp,
    status,
    refetch: refetchTable,
  } = useQuery({
    queryKey: ['getTimelogs', currentPeriod],
    queryFn: () => getTimelogsHistory(currentUser?.id, currentPeriod),
  });

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(status === 'loading');
    if (status !== 'success') return;
    const {data: respData} = resp;
    setData(respData?.data?.data);
  }, [resp, status]);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  return {
    greating,
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    refetchTable,
    prepareRow,
    isLoading,
  };
};
