import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable'
import {UserEditModal} from './user-edit-modal/UserEditModal'
import {KTCard, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { useTimelogs } from '../../../../pages/dashboard/hooks/useTimelogs'
import { useTranslate } from '../../../../function/translate'
import { PageTitle } from '../../../../../_metronic/layout/core'

const UsersList = () => {
  const {itemIdForUpdate} = useListView()
  const {
    greating,
  } = useTimelogs()
  const translate = useTranslate();
  return (
    <>
      <div style={{color: "black"}}>
        <PageTitle breadcrumbs={[]} imageUri={toAbsoluteUrl('/media/sun-icon.svg')}>          
            {greating}          
        </PageTitle>
      </div>
      <KTCard>
        <UsersListHeader />
        <div className='separator my-2'></div>
        <UsersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListWrapper}
